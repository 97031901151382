<template>
  <div class="onboard">
    <div class="onboard-logo">
      <img width="112" src="/images/logo-v.jpg" alt="logo">
    </div>
    <div class="onboard-title"> {{ $t("welcome.title") }} </div>
    <div class="onboard-title"> {{ $t("welcome.title2") }} </div>
    <div class="video-wrapper">
      <vue-plyr>
        <div data-plyr-provider="youtube" :data-plyr-embed-id="youtubeid"></div>
      </vue-plyr>
    </div>
    <div class="bottom-actions pt-10">
      <div class="btn-wrap">
        <v-btn class="btn-start"
               color="secondary"
               rounded
               large
               width="280"
               @click="getStarted"
        >
          {{ $t("welcome.button.start") }}
        </v-btn>
      </div>
    </div>
    <LanguageSwitcher />
    <div class="faq-action d-flex justify-end align-center px-5 pt-5">
      <strong>{{ $t("footer.faq") }}</strong>
      <v-btn class="mx-2" fab dark small color="secondary" to="/faq">
        <v-icon dark>mdi-help</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LanguageSwitcher from '../../components/language-switcher';

export default {
  name: 'welcome',
  metaInfo: {
    title: 'Welcome'
  },
  components: { LanguageSwitcher },
  data () {
    return {
    };
  },
  methods: {
    getStarted () {
      this.$router.push({ name: 'auth' });
    }
  },
  computed: {
    youtubeid () {
      if (this.$i18n.locale === 'en') {
        return '9UFgMEBmZWc';
      } else {
        return 'XoIW0vuWrc4';
      }
    }
  }
};
</script>
